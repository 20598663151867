<template>
  <div class="forgot-pass">
    <Loader></Loader>
    <div class="forgot-pass-content">
      <div class="forgot-pass-content_logo">
        LOGO
      </div>
      <h6 class="forgot-pass-content_title mt-2">{{ title }}</h6>
      <b-form @submit.prevent="onSubmit">
        <b-form-input
          id="input-1"
          v-model="email"
          type="email"
          required
          class="mt-5"
          placeholder="email"
          v-if="!isConfirm"
          :state="checkErrorEmail"
          aria-describedby="input-1-live-feedback"
        ></b-form-input>
        <b-form-invalid-feedback id="input-1-live-feedback" class="text-left">
          Tài khoản không tồn tại.
        </b-form-invalid-feedback>
        <b-form-group v-if="isConfirm">
          <b-form-input
            id="input-1"
            v-model="$v.form.pinCode.$model"
            type="text"
            class="mt-5"
            placeholder="Pin"
            :state="validatePincode('pinCode')"
            :required="isConfirm"
            aria-describedby="input-2-live-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="input-2-live-feedback" class="text-left">
            {{ messegeCode }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group v-if="isConfirm">
          <b-form-input
            id="input-2"
            v-model="$v.form.passWord.$model"
            type="password"
            :state="validateState('passWord')"
            placeholder="Mật khẩu mới"
            :required="isConfirm"
            aria-describedby="input-3-live-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="input-3-live-feedback" class="text-left">
            Mật khẩu dài 6 kí tự
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group v-if="isConfirm">
          <b-form-input
            id="input-3"
            v-model="$v.form.passWordConfirm.$model"
            type="password"
            :state="validatePassWordConfirm('passWordConfirm')"
            placeholder="Nhập lại mật khẩu"
            :required="isConfirm"
            aria-describedby="input-4-live-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="input-4-live-feedback" class="text-left">
            {{ messeageErrorPass }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-button type="submit" variant="primary" class="w-100 mt-4">
          Xác nhận
        </b-button>
      </b-form>
    </div>
  </div>
</template>

<style lang="scss">
@import "./ForgotPass.scss";
</style>

<script>
import { validationMixin } from "vuelidate";
import { minLength, maxLength } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import Loader from "@/components/Loader.vue";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/store/htmlclass.module.js";

export default {
  mixins: [validationMixin],
  name: "ForgotPass",
  components: {
    Loader
  },
  data() {
    return {
      email: "",
      isConfirm: false,
      form: {
        pinCode: "",
        passWord: "",
        passWordConfirm: ""
      },
      loading: false,
      errorEmail: true,
      isMatchPass: false,
      messeageErrorPass: "",
      title: "Nhập email để lấy lại mã pin",
      messegeCode: "Code gồm 6 kí tự",
      errorsCode: ""
    };
  },

  validations: {
    form: {
      pinCode: {
        maxLength: maxLength(6),
        minLength: minLength(6)
      },
      passWord: {
        maxLength: maxLength(6),
        minLength: minLength(6)
      },
      passWordConfirm: {
        maxLength: maxLength(6),
        minLength: minLength(6)
      }
    }
  },

  watch: {
    email() {
      if (this.email) {
        this.errorEmail = true;
      }
    }
  },

  computed: {
    checkErrorEmail() {
      return this.email ? this.errorEmail : null;
    }
  },

  methods: {
    onSubmit() {
      const data = {
        email: this.email
      };

      if (this.form.passWord === this.form.passWordConfirm) {
        this.isMatchPass = true;
      } else {
        this.isMatchPass = false;
      }

      if (!this.isConfirm) {
        this.$store.dispatch(ADD_BODY_CLASSNAME, "kt-page--loading");
        setTimeout(() => {
          this.$store
            .dispatch("forgotPass", data)
            .then(() => {
              this.isConfirm = true;
              this.errorEmail = true;
              this.title = "Nhập code và mật khẩu mới";
            })
            .catch(() => {
              this.errorEmail = false;
            })
            .finally(() => {
              this.$store.dispatch(REMOVE_BODY_CLASSNAME, "kt-page--loading");
            });
        }, 1000);
      } else {
        this.$store.dispatch(ADD_BODY_CLASSNAME, "kt-page--loading");
        const dataUpdatePass = {
          pin: this.form.pinCode,
          password: this.form.passWord
        };
        this.$store
          .dispatch("updatePass", dataUpdatePass)
          .then(() => {
            Swal.fire({
              toast: true,
              position: "top-end",
              icon: "success",
              title: "Cập nhật mật khẩu thành công!",
              showConfirmButton: false,
              timer: 3000
            });
            this.isConfirm = false;
            this.$router.push("/login");
          })
          .catch(() => {
            this.errorsCode = this.$store.state.auth.errorsUpdatePass;
          })
          .finally(() => {
            this.validatePincode("pinCode");
            this.$store.dispatch(REMOVE_BODY_CLASSNAME, "kt-page--loading");
          });
      }
    },

    validateState(name) {
      let { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    validatePassWordConfirm(name) {
      let { $dirty, $error } = this.$v.form[name];
      if (this.form.passWordConfirm && this.form.passWordConfirm.length !== 6) {
        this.messeageErrorPass = "Mật khẩu dài 6 kí tự";
      } else if (
        this.form.passWord &&
        this.form.passWordConfirm &&
        this.form.passWord !== this.form.passWordConfirm
      ) {
        this.messeageErrorPass = "Mật khẩu không khớp";
        $error = true;
      }
      return $dirty ? !$error : null;
    },

    validatePincode(name) {
      let { $dirty, $error } = this.$v.form[name];
      if (this.errorsCode) {
        this.messegeCode = "Mã code không khớp";
        return this.form.pinCode ? false : null;
      } else {
        this.messegeCode = "Code dài 6 kí tự";
        return $dirty ? !$error : null;
      }
    }
  }
};
</script>
